import store from './store';

export default
[
    {
        id: 'Dossier',
        name: store.getters.translate('dossier'),
        notifable: false,
        children: [
            {
                id: 'candidate',
                name: store.getters.translate('candidate'),
                notifable: true
            },
            {
                id: 'scheme',
                name: store.getters.translate('scheme'),
                notifable: false
            },
            {
                id: 'exam',
                name: store.getters.translate('exam'),
                notifable: false,
                children: [
                    {
                        id: 'location',
                        name: store.getters.translate('location'),
                        plural: false,
                        notifable: true,
                    },
                    {
                        id: 'owner',
                        name: store.getters.translate('owner'),
                        plural: false,
                        notifable: true,
                    },
                    {
                        id: 'sessions',
                        name: store.getters.translate('sessions'),
                        plural: true,
                        notifable: false,
                    },
                    {
                        id: 'assessors',
                        name: store.getters.translate('assessors'),
                        plural: true,
                        notifable: true,
                    },
                    {
                        id: 'dossiers',
                        name: store.getters.translate('dossiers'),
                        plural: true,
                        notifable: false,
                        children: [
                            {
                                id: 'candidates',
                                name: store.getters.translate('candidates'),
                                plural: true,
                                notifable: true,
                            },
                        ]
                    },
                ],
            },
            {
                id: 'notes',
                name: store.getters.translate('notes'),
                plural: true,
                notifable: false,
            },
        ],
    },
    {
        id: 'Certificate',
        name: store.getters.translate('certificate'),
        notifable: false,
        children: [
            {
                id: 'candidate',
                name: store.getters.translate('candidate'),
                notifable: true
            },
            {
                id: 'template',
                name: store.getters.translate('template'),
                plural: false,
                notifable: false
            },
            {
                id: 'scheme',
                name: store.getters.translate('scheme'),
                plural: false,
                notifable: false
            },
        ],
    },
    {
        id: 'Candidate',
        name: store.getters.translate('candidate'),
        notifable: true,
        children: [
            {
                id: 'dossiers',
                name: store.getters.translate('dossiers'),
                plural: true,
                notifable: false,
                children: [
                    {
                        id: 'rootExam',
                        name: store.getters.translate('root_exam'),
                        plural: false,
                        notifable: false
                    },
                ]
            },
            {
                id: 'exams',
                name: store.getters.translate('exams'),
                plural: true,
                notifable: false,
            },
            {
                id: 'certificates',
                name: store.getters.translate('certificates'),
                plural: true,
                notifable: false,
            },
            // {
            //     id: 'contacts',
            //     name: store.getters.translate('contacts'),
            //     plural: true,
            //     notifable: true,
            // },
            {
                id: 'companies',
                name: store.getters.translate('companies'),
                plural: true,
                notifable: false,
                children: [
                    {
                        id: 'contacts',
                        name: store.getters.translate('contacts'),
                        plural: true,
                        notifable: false,
                    },
                ]
            },
        ],
    },
    {
        id: 'Scheme',
        name: store.getters.translate('scheme'),
        plural: false,
        notifable: false,
    },
    {
        id: 'Exam',
        name: store.getters.translate('exam'),
        notifable: false,
        children: [
            {
                id: 'location',
                name: store.getters.translate('location'),
                plural: false,
                notifable: true,
            },
            {
                id: 'owner',
                name: store.getters.translate('owner'),
                plural: false,
                notifable: true,
            },
            {
                id: 'sessions',
                name: store.getters.translate('sessions'),
                plural: true,
                notifable: false,
            },
            {
                id: 'assessors',
                name: store.getters.translate('assessors'),
                plural: true,
                notifable: true,
            },
            {
                id: 'dossiers',
                name: store.getters.translate('dossiers'),
                plural: true,
                notifable: false,
                children: [
                    {
                        id: 'candidates',
                        name: store.getters.translate('candidates'),
                        plural: true,
                        notifable: true,
                    },
                ]
            },
        ],
    },
    {
        id: 'Session',
        name: store.getters.translate('session'),
        notifable: false,
        children: [
            {
                id: 'exam',
                name: store.getters.translate('exam'),
                plural: false,
                notifable: false,
                children: [
                    {
                        id: 'dossiers',
                        name: store.getters.translate('dossiers'),
                        plural: true,
                        notifable: false,
                    },
                    {
                        id: 'candidates',
                        name: store.getters.translate('candidates'),
                        plural: true,
                        notifable: true,
                    },
                ]
            },
            {
                id: 'schemeSession',
                name: store.getters.translate('schemesession'),
                plural: false,
                notifable: false,
            },
        ],
    },
    {
        id: 'Note',
        name: store.getters.translate('note'),
        notifable: false,
        children: [
            {
                id: 'candidate',
                name: store.getters.translate('candidate'),
                notifable: true
            },
        ],
    },
]