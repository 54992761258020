<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :disabled="loading" :loading="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" dense flat slot="header">
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn v-if="canDelete" text small @click.stop="archive">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text class="pt-5">
              <template v-for="field in usual_fields">
                <base-field
                    :key="field.name"
                    :value="record[field.name]"
                    :field="field"
                    @change="usualFieldDataChanged"/>
              </template>
              <template v-for="field in custom_fields">
                <base-field
                    :key="field.name"
                    :value="record.custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
              <template v-if="record && record.candidates.length > 0">
                <small>{{ $store.getters.translate("candidates") }}</small><br />
                <v-chip
                  v-for="candidate in record.candidates"
                  :key="candidate.id"
                  @click="$router.push('/candidates/' + candidate.id)"
                  class="ma-1"
                  color="primary"
                  small>
                  <span class="mr-1"> {{ candidate.name }}</span>
                  <small>({{ $store.getters.translate("candidate_id") }}: {{ candidate.id }})</small>
                </v-chip>
              </template>
              <br/>
              <template v-if="record && record.assessors.length > 0">
                <small>{{ $store.getters.translate("assessors") }}</small><br />
                <v-chip
                  v-for="assessor in record.assessors"
                  :key="assessor.id"
                  @click="$router.push('/assessors/' + assessor.id)"
                  class="ma-1"
                  color="primary"
                  small>
                  <span class="mr-1"> {{ assessor.name }}</span>
                </v-chip>
              </template>
              <br/>
              <template v-if="record && record.companies.length > 0">
                <small>{{ $store.getters.translate("companies") }}</small><br />
                <v-chip
                  v-for="company in record.companies"
                  :key="company.id"
                  @click="$router.push('/companies/' + company.id)"
                  class="ma-1"
                  color="primary"
                  small>
                  <span class="mr-1"> {{ company.name }}</span>
                </v-chip>
              </template>
              <br/>
              <template v-if="record && record.exams.length > 0">
                <small>{{ $store.getters.translate("exams") }}</small><br />
                <v-chip
                  v-for="exam in record.exams"
                  :key="exam.id"
                  @click="$router.push('/exams/' + exam.id)"
                  class="ma-1"
                  color="primary"
                  small>
                  <span class="mr-1"> {{ exam.name }}</span>
                </v-chip>
              </template>
              <br/>
              <template v-if="record && record.dossiers.length > 0">
                <small>{{ $store.getters.translate("dossiers") }}</small><br />
                <v-chip
                  v-for="dossier in record.dossiers"
                  :key="dossier.id"
                  @click="$router.push('/dossiers/' + dossier.id)"
                  class="ma-1"
                  color="primary"
                  small>
                  <span class="mr-1"> {{ dossier.name }}</span>
                </v-chip>
              </template>
              <br/>
              <template v-if="record && record.schemes.length > 0">
                <small>{{ $store.getters.translate("schemes") }}</small><br />
                <v-chip
                  v-for="scheme in record.schemes"
                  :key="scheme.id"
                  @click="$router.push('/schemes/' + scheme.id)"
                  class="ma-1"
                  color="primary"
                  small>
                  <span class="mr-1"> {{ scheme.name }}</span>
                </v-chip>
              </template>
              <br/>
              <v-select
                  v-if="model_type === 'Exam' || model_type === 'Candidate'"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('attach_to')"
                  v-model="selected_model"
                  :items="getAttachToModels"/>
              <v-select
                  v-if="selected_model === 'Assessor'"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  v-model="record.assessor_ids"
                  clearable
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  item-value="id"
                  item-text="name"
                  :items="assessors"
                  :menu-props="{ bottom: true, offsetY: true }"/>
              <v-select
                  v-if="selected_model === 'Candidate'"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  v-model="record.candidate_ids"
                  clearable
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  item-value="id"
                  item-text="name"
                  :items="candidates"
                  :menu-props="{ bottom: true, offsetY: true }"/>
              <v-select
                  v-if="selected_model === 'Exam'"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  v-model="record.exam_ids"
                  clearable
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  item-value="id"
                  item-text="name"
                  :items="exams"
                  :menu-props="{ bottom: true, offsetY: true }"/>
              <v-select
                  v-if="selected_model === 'Company'"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  v-model="record.company_ids"
                  clearable
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  item-value="id"
                  item-text="name"
                  :items="companies"
                  :menu-props="{ bottom: true, offsetY: true }"/>
              <v-select
                  v-if="selected_model === 'Dossier'"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  v-model="record.dossier_ids"
                  clearable
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  item-value="id"
                  item-text="name"
                  :items="dossiers"
                  :menu-props="{ bottom: true, offsetY: true }"/>
              <v-checkbox :label="$store.getters.translate('generate_document')" v-model="record.generate_document" dense hide-details />
              <template v-if="record.generate_document">
                <div class="pa-5 mt-3">
                  <v-select
                      :label="$store.getters.translate('template')"
                      v-model="record.template_id"
                      validate-on-blur
                      :items="$lodash.sortBy(templates, 'name')"
                      item-value="id"
                      item-text="name"
                      @change="setDocumentName"
                      chips/>
                  <v-text-field
                      ref="document_name"
                      :label="$store.getters.translate('document_name')"
                      v-model="record.document_name"
                      counter/>
                  <v-btn @click="save(true)" block small>
                      {{ $store.getters.translate("generate_document") }}
                  </v-btn>
                </div>
              </template>
              <base-row
                class="mt-3"
                v-for="(file, index) in $lodash.orderBy(record.files, 'created_at', 'desc')"
                :key="index"
                :label="$store.getters.translate('file')"
                :value="file.name"
                @click="preview(file)"
                :link="true"
                :hide_copy="true"
                :deletable="true"
                @delete="deleteFile(file)"/>
              <v-row>
                <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                  <v-file-input
                    class="mt-3"
                    :label="$store.getters.translate('add_files')"
                    show-size
                    v-model="record.new_files"
                    multiple
                    @change="saveFiles"/>
                </v-col>
              </v-row>
              <base-tags v-if="tags_required" v-model="record.tags" :module_name="module_name"/>
            </v-card-text>
          </div>
        <v-footer-panel ref="footer_panel" :model="page.name"/>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import related_models_tree from "../../related_models_tree";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["module_name", "model_type", "model_id", "assessors", "candidates", "companies", "dossiers", "exams", "modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("note"),
        name: "notes",
        model: "note",
      },
      loading: false,
      record: {
        new_files: [],
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      file: "",
      templates: [],
      selected_model: "",
      id: null,
    };
  },
  created() {
    this.record = helpFunctions.default_note;
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(id) {
      this.getTemplates();
      this.usual_fields = [];
      this.custom_fields = [];
      this.openModal(this.modal_name);
      this.loading = true;
      this.id = id;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then((response) => {
          this.record = response.data;
          this.record.document_name = null;
          if(this.model_type && this.model_id) {
            this.record.model_type = this.model_type;
            this.record.model_id = this.model_id;
          }
          this.$refs.footer_panel.record = this.record;
          this.$refs.footer_panel.load();
          this.getFields();
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    preview(file) {
      this.$emit("document_preview", file);
    },
    save(keep_open) {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          this.loading = true;
          if (this.model_type) {
            this.record.model_type = this.model_type;
          }
          if (this.model_id) {
            this.record.model_id = this.model_id;
          }
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$emit("refresh");
                  if (!keep_open) {
                    this.reset();
                  } else {
                    this.load(this.id);
                  }
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            this.loading = true;
            this.$http
              .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
              .then(() => {
                this.$toasted.success(this.$store.getters.translate("success"));
                this.$emit("refresh");
                this.loading = false;
                this.reset();
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            this.loading = true;
            this.$http
              .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
              .then(() => {
                this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                this.loading = false;
                this.load(this.id);
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        }
      );
    },
    saveFiles() {
      if (!this.record.new_files) {
        return false;
      }
      this.loading = true;
      const formData = new FormData();
      this.record.new_files.forEach((file) => {
        formData.append("attachment[]", file);
      });
      formData.append("note_id", this.record.id);
      this.$http
        .post(this.$store.getters.appUrl + "v2/notes/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("successfully_saved"));
          this.load(this.id);
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    setDocumentName() {
      let template = this.templates.find((template) => template.id === this.record.template_id);
      if (template) {
        this.record.document_name = template.name;
        this.$refs.document_name.focus();
      }
    },
    addDropFile(e) {
      this.loading = true;
      const formData = new FormData();
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        formData.append("attachment[]", e.dataTransfer.files[i]);
      }
      formData.append("note_id", this.record.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/notes/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load(this.id);
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getTemplates() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/templates")
          .then((response) => {
            this.templates = [];
            response.data.data.forEach((option) => {
              this.templates.push({
                id: option.id,
                name: option.name,
              });
            });
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    reset() {
      this.record = helpFunctions.default_note;
      this.closeModal(this.modal_name);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    canDelete() {
      return this.$can("delete", this.page.model);
    },
  },
  computed: {
    relatedModels() {
      if (this.model_type) {
        return [related_models_tree.find((model) => model.id === this.model_type)];
      }
      return [];
    },
    getAttachToModels() {
      var models = [{
          value: "Assessor",
          text: this.$store.getters.translate("assessors"),
        },
        {
          value: "Company",
          text: this.$store.getters.translate("companies"),
        },
        {
          value: "Dossier",
          text: this.$store.getters.translate("dossiers"),
        }];
      if (this.model_type === "Exam") {
        models.push({
          value: "Candidate",
          text: this.$store.getters.translate("candidates"),
        });
      } else if (this.model_type === "Candidate") {
        models.push({
          value: "Exam",
          text: this.$store.getters.translate("exams"),
        });
      }
      return this.$lodash.sortBy(models, "text");
    },
  },
  watch: {
    record: {
      handler() {
        if (this.model_type === "Exam" || this.model_type === "Candidate") {
          this.record.assessor_ids = this.$lodash.map(this.record.assessors, "id");
          this.record.candidate_ids = this.$lodash.map(this.record.candidates, "id");
          this.record.dossier_ids = this.$lodash.map(this.record.dossiers, "id");
          this.record.company_ids = this.$lodash.map(this.record.companies, "id");
          this.record.scheme_ids = this.$lodash.map(this.record.schemes, "id");
        }
      },
    },
  },
};
</script>
