import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"fullscreen":true}},[_c(VCard,{attrs:{"loading":_vm.loading,"height":"100%"}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$store.getters.translate("preview")))]),_c(VSpacer)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,{staticClass:"text-center",staticStyle:{"height":"98vh"}},[_c(VRow,{staticStyle:{"height":"100%"}},[_c(VCol,[(!_vm.blob)?_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c(VFlex,{staticClass:"text-center",attrs:{"row":"","align-center":""}},[(!_vm.blob)?_c('div',{staticClass:"justify-center text-center"},[(!_vm.not_found)?_c(VProgressCircular,{attrs:{"size":100,"color":"primary","indeterminate":""}}):_c(VIcon,{attrs:{"x-large":"","color":"red"}},[_vm._v("mdi-minus-circle")]),_c('transition',{attrs:{"name":"fade"}},[_c('h3',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.message))])])],1):_vm._e()])],1):_c('p',{staticClass:"mt-5",staticStyle:{"height":"100%"},attrs:{"align":"center"}},[_c('iframe',{ref:"preview",staticStyle:{"overflow":"hidden","height":"100%","width":"100%","display":"block"},attrs:{"height":"100%","width":"100%","src":_vm.blob,"frameborder":"0"}})])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }