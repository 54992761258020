<template>
  <BaseDraggableModal :name="modal_name" :fullscreen="true" :ref="modal_name">
    <v-card :loading="loading" height="100%">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" slot="header" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.translate("preview") }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text style="height: 98vh" class="text-center">
            <v-row style="height: 100%">
              <v-col>
                <v-layout v-if="!blob" align-center justify-center column fill-height>
                  <v-flex class="text-center" row align-center>
                    <div class="justify-center text-center" v-if="!blob">
                      <v-progress-circular v-if="!not_found" :size="100" color="primary" indeterminate/>
                      <v-icon v-else x-large color="red">mdi-minus-circle</v-icon>
                      <transition name="fade">
                        <h3 class="mt-5">{{ message }}</h3>
                      </transition>
                    </div>
                  </v-flex>
                </v-layout>
                <p v-else align="center" style="height: 100%" class="mt-5">
                  <iframe
                      ref="preview"
                      style="
                          overflow: hidden;
                          height: 100%;
                          width: 100%;
                          display: block;"
                      height="100%"
                      width="100%"
                      :src="blob"
                      frameborder="0"/>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modal_name"],
  data() {
    return {
      loading: true,
      blob: null,
      not_found: false,
      message: this.$store.getters.translate("loading"),
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(file) {
      this.loading = true;
      if (file.content_type === "application/pdf" || file.content_type === "image/jpeg" || file.content_type === "image/png") {
        this.preview(file);
      } else {
        this.download(file);
      }
    },
    preview(file) {
      this.$http
        .get(this.$store.getters.appUrl + "v2/files/" + file.id, { responseType: "blob" })
        .then((response) => {
          this.loading = false;
          var blob;
          if (file.content_type === "application/pdf") {
            blob = new Blob([response.data], {type: "application/pdf"});
          }
          else if (file.content_type === "image/jpeg") {
            blob = new Blob([response.data], {type: "image/jpeg"});
          }
          else if (file.content_type === "image/png") {
            blob = new Blob([response.data], {type: "image/png"});
          }
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          this.blob = link.href;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 404) {
            this.message = this.$store.getters.translate("nothing_found");
            this.not_found = true;
          }
        });
    },
    download(file) {
      this.$http
        .get(this.$store.getters.appUrl + "v2/files/" + file.id, { responseType: "blob" })
        .then((response) => {
          this.loading = false;
          const blob = new Blob([response.data], { type: file.content_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
          this.reset();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    reset() {
      this.closeModal(this.modal_name);
    },
  },
};
</script>